import React from "react"
import {useTranslation} from "react-i18next"

import './page404.component.less';

import main404 from "../../assets/img/page404/404-main.jpg";
import small404 from "../../assets/img/page404/404-small.jpg";
import {Link} from "gatsby-plugin-react-i18next";

const NotFoundPageComponent = () => {
    const {i18n, t} = useTranslation()

    return (
        <div>
            <div className="main-block-404">
                <div
                    className="wrapper_block wrapper_block__block wrapper_block__block--no-padding-bottom wrapper_block__block--no-border">
                    <div className="main-block__text">
                        <div
                            className="main-block__title main-block__title--max_width">
                            <div
                                dangerouslySetInnerHTML={{__html: t("unfortunatelyAPage")}}/>
                        </div>
                        <div
                            className="main-block__description main-block__description--flex">
                            <div
                                dangerouslySetInnerHTML={{__html: t("CheckOutOurHome")}}/>
                        </div>
                        <Link to="/" language={i18n.language}>
                            <div className="block-404-btn">
                                <div
                                    className="main-block__btn main-page_second-block__main-element--right-block-btn">
                                    <div
                                        dangerouslySetInnerHTML={{__html: t("homePage")}}/>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className="main-block__img">
                        <div
                            className="main-block__main-img-block main-block__main-img-block--mobile">
                            <div className="main-block__main-img-block--1"/>
                            <div className="main-block__main-img-block--2">
                                <img alt="" className="main-block__main-img"
                                     src={main404}/>
                                <div className="main-block__artefact"/>
                            </div>
                        </div>
                        <div
                            className="main-block__small-img-block main-block__small-img-block--mobile">
                            <div className="main-block__small-img-block--1">
                                <img alt=""
                                     className="main-block__small-img"
                                     src={small404}/>
                            </div>
                            <div className="main-block__small-img-block--2"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NotFoundPageComponent
